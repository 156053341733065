<template>
    <div id="main-box" :class="{'isEn':lang == 'en'}">
      <loading v-if="loading" :loading="loading"></loading>
      <div v-else>
        <van-swipe class="swipe" :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" />
        </van-swipe-item>
        </van-swipe>  
        <ul id="cardView">
            <li v-for="(c,index) in $t('serveProMsg')" @touchmove="handleCardTouch"  @touchend="handleCardTouchend" :key="index" class="resent-search-tag3">
                <el-card class="card" shadow="never">
                    <div class="item">
                      <h3 class="serveTitle">{{c.title}}</h3>
                      <img v-lazy="c.cardImgUrl">
                    </div>
                </el-card>
            </li>
        </ul> 
        <el-row type="flex" justify="center" class="introduce">
          <el-col>
            <el-card class="card" shadow="none">
               <transition name="el-fade-in-linear" @after-leave="handleSPTrans">
                <div v-if="SPState" class="container">
                    <div class="item leftItem">
                      <img v-lazy="$t('serveProMsg')[serveProIndex].first.imgUrl" alt="">
                      <p class="text_l3">{{$t('serveProMsg')[serveProIndex].first.itemMsg}}</p>
                  </div>
                  <div class="bottomContainer">
                      <div class="item" v-for="(s,index) in $t('serveProMsg')[serveProIndex].other" v-if="index < 2" :key="index">
                        <img v-lazy="s.imgUrl" alt="">
                        <h4 class="text_ov_el">{{s.title}}</h4>
                        <p class="text_l2">{{s.itemMsg}}</p>
                        <a href="javascript:;" v-if="index == 0" @click="jumpPage('MServicesAvailable',serveProIndex)">了解更多</a>
                      </div>
                  </div>
                </div>
               </transition>
            </el-card>
          </el-col>
        </el-row>
        <area-title :cname="$t('topBar.n4')"></area-title> 
        <div class="casesShow">
          <el-row>
            <el-col >
              <a-steps progress-dot :current="current" direction="horizontal" @change="onStepChange">
                <a-step v-for="(item,index) in $t('serveProMsg')" :key="index" :title="item.title"  />
              </a-steps>
            </el-col>
          </el-row>    
          <div class="steps-content">
            <transition name="el-fade-in-linear" @after-leave="handleCaseTrans">
              <div v-if="caseState">
                <case-container :caseMsg="$t('casesMsg')[steps[current].content - 1]" :isMobile="true" :pindex="current"></case-container>
              </div>
            </transition>
          </div> 
        </div>
        <area-title :cname="$t('topBar.n5')"></area-title> 
        <el-card class="card" shadow="never">
          <div class="mnewsCenter">
            <div class="item topItem" @click="goToNews(newsMsg[0].id)">
              <img v-lazy="this.newsMsg[0].thumbimg" alt="">
              <h3>{{this.newsMsg[0].title}}</h3>
              <p class="text_l2">{{this.newsMsg[0].intro}}</p>
              <p class="newsTime">{{this.newsMsg[0].createdAt}}</p>
            </div>
            <div class="bottomItem">
              <div class="item" v-for="(n,index) in newsMsg" v-if="index >=1 && index <= 2" :key="index" @click="goToNews(n.id)">
                    <img v-lazy="n.thumbimg" alt="">
                    <h3 class="text_ov_el">{{n.title}}</h3>
                    <p class="text_l2">{{n.intro}}</p>
                    <p class="newsTime">{{n.createdAt}}</p>
                  </div>
            </div>
          </div>
        </el-card>
        <area-title :cname="$t('topBar.n7')"></area-title>
        <div class="servAv">
          <el-row type="flex" justify="center">
            <div>
              <el-col class="cardsBox" v-for="(img,index) in serverImgList" v-if="index <= 2" :key="index" :span="3.5">
                <div class="text item">
                  <img v-lazy="img.imgUrl">
                </div>
              </el-col>
            </div>
          </el-row>
          <el-row type="flex" justify="center">
            <div>
              <el-col class="cardsBox" v-for="(img,index) in serverImgList" v-if="index >= 3" :key="index" :span="3.5">
                <div class="text item">
                  <img v-lazy="img.imgUrl">
                </div>
              </el-col>
            </div>
          </el-row>
        </div>
        <el-row class="brandImage" type="flex" justify="center">
          <el-col>
            <img v-lazy="'http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-%E5%90%88%E4%BD%9C%E5%AE%A2%E6%88%B7.png'" alt="">
          </el-col>
        </el-row>
      </div>
    </div>
</template>

<script>
import AreaTitle from "components/AreaTitle";
import CaseContainer from "components/CaseContainer";
import Loading from "components/Loading";

import Bus from "@/utils/bus.js";

import { bannerApi, newsApi } from "@/request/api.js";

export default {
  name: "NewsCenter",
  components: {
    AreaTitle,
    CaseContainer,
    Loading,
  },
  data() {
    return {
      SPState: true,
      caseState: true,
      iactive: 0,
      transState: 0,
      loading: true,
      current: 0,
      serveProIndex: 0,
      timmer: "",
      images: [],
      newsMsg: [],
      steps: [
        {
          title: "First",
          content: "1",
        },
        {
          title: "Second",
          content: "2",
        },
        {
          title: "Last",
          content: "3",
        },
        {
          title: "Last",
          content: "4",
        },
        {
          title: "Last",
          content: "5",
        },
        {
          title: "Last",
          content: "6",
        },
        {
          title: "Last",
          content: "7",
        },
      ],
      serverImgList: [
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-10%E5%B9%B4%E8%99%9A%E6%8B%9F%E7%8E%B0%E5%AE%9E%E7%BB%8F%E9%AA%8C.png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-20%E5%B9%B4%E6%95%B0%E5%AD%97%E5%B1%95%E7%A4%BA%E7%BB%8F%E9%AA%8C.png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-%E5%85%A8%E7%A8%8B%E9%A1%BE%E9%97%AE%E6%8C%87%E5%AF%BC(1).png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-30%E5%B9%B4%E9%A1%BE%E9%97%AE%E7%BB%8F%E9%AA%8C.png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-24%E5%B0%8F%E6%97%B6%E5%AE%A2%E6%9C%8D.png",
        },
      ],
    };
  },
  methods: {
    handleSPTrans() {
      this.serveProIndex = this.transState;
    },
    handleCaseTrans() {
      this.current = this.transState;
    },
    goToNews(msg) {
      let routeData = this.$router.resolve({
        name: "News",
        query: {
          pageIndex: msg,
          eq: "m",
        },
      });
      window.open(routeData.href, "_blank");
    },
    jumpPage(routerName, index) {
      this.$router.push({
        name: routerName,
        params: {
          index,
        },
      });
    },
    onStepChange(current) {
      this.caseState = !this.caseState;
      this.transState = current;
      setTimeout(() => {
        this.caseState = !this.caseState;
      }, 300);
    },
    changeLanguage() {
      this.btnIsActive = !this.btnIsActive;
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh");
      localStorage.setItem("languageSet", this.$i18n.locale);
    },
    handleCardTouch(event) {
      const cardItems = document.getElementsByClassName("card");
      this.timmer = setInterval(() => {
        cardItems.forEach((item, value) => {
          const distL = item.getBoundingClientRect().left;
          const pmW = document.body.clientWidth / 2;
          if (distL > pmW - 70 && distL < pmW + 5) {
            item.classList.add("cardActive");
            this.serveProIndex = value;
          } else {
            item.classList.remove("cardActive");
          }
        });
      }, 100);
      setTimeout(() => {
        cardItems[this.serveProIndex].classList.add("cardActive");
      }, 1000);
    },
    handleCardTouchend() {
      clearInterval(this.timmer);
    },
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
  },
  watch: {
    immediate: true,
    lang(newLang, oldLang) {
      newsApi(newLang).then((res) => {
        this.newsMsg = res.data.rows;
        this.newsMsg.forEach((value, index, arr) => {
          this.newsMsg[index].createdAt = value.createdAt.slice(0, 10);
        });
      });
    },
  },
  mounted() {
    bannerApi()
      .then((res) => {
        res.data.rows.forEach((value, index, arr) => {
          this.images.push(value.thumbimg);
        });
        this.loading = false;
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    newsApi(this.$store.state.lang).then((res) => {
      this.newsMsg = res.data.rows;
      this.newsMsg.forEach((value, index, arr) => {
        this.newsMsg[index].createdAt = value.createdAt.slice(0, 10);
      });
    });
    // const cardItems = document.getElementsByClassName("card");
    // cardItems.forEach((item, value) => {
    //   console.log("item, value: ", item.getBoundingClientRect().left, value);
    // });
    console.log("pmw", document.body.clientWidth / 2);
    this.handleCardTouch();
  },
};
</script>

<style lang="scss">
@import "assets/scss/base.scss";

.isEn {
  #cardView .el-card {
    img {
      width: 30px;
      height: 32px;
    }
  }
  #cardView h3 {
    height: 35.2px;
    padding-top: 0 !important;
  }
}

.ant-steps-dot {
  transform: translatey(50%);
  transform: scale(0.66);
  margin-top: 10px;
  margin-left: -80px;
  .ant-steps-item {
    width: 80px;
  }
  .ant-steps-item-title {
    margin: 12px 0 12px 0px;
    font-size: 12px;
    width: 86px;
    height: 33px;
    color: #fff !important;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .ant-steps-icon-dot {
    background: #fff !important;
  }
}
.ant-steps-item-active {
  .ant-steps-item-title {
    font-weight: bold;
  }
  .ant-steps-icon-dot {
    width: 50px !important;
    height: 50px !important;
    transform: translate(-36%, -36%);
    background: url("http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5%E6%A1%88%E4%BE%8B%E5%AD%90%E9%A1%B9active.png")
      top left no-repeat !important;
  }
}

#cardView .el-card {
  width: 80px;
  height: 90px;
  padding: 5px 0 0 0;
  .el-card__body {
    padding: 0;
  }
  img {
    width: 39px;
    height: 42px;
  }
}
</style>

<style lang="scss" scoped>
@import "assets/scss/mixin.scss";
@import "assets/scss/global.scss";

#main-box {
  overflow: hidden;
}

.swipe {
  top: 50px;
  height: 168px;
  img {
    object-fit: cover;
  }
}

.container {
  width: 100%;
  height: 535px;
  .leftItem {
    padding: 5px;
    img {
      width: 100%;
      height: 231px;
      object-fit: cover;
      border-radius: 5px;
    }
    p {
      height: 63px;
    }
  }
  .bottomContainer {
    @include flex("center", "center");
    flex-wrap: wrap;
    .item {
      width: 45%;
      margin: 0 8px;
      img {
        object-fit: cover;
        height: 100px;
        box-shadow: 0px 1px 7px 0px rgba(181, 181, 181, 0.35);
      }
      h4 {
        margin: 7px 0 0 0;
        font-weight: bold;
      }
      p {
        margin: 5px 0 0 0;
        height: 40px;
      }
      a {
        font-size: 12px;
        display: block;
        width: 80px;
        height: 30px;
        border: 2px outset rgb(49, 100, 214);
        border-radius: 3px;
        background: #fff;
        text-align: center;
        color: rgb(49, 100, 214);
        text-decoration: none;
        padding: 5px;
      }
    }
  }
}
.casesShow {
  height: 565px;
  background-color: rgb(12, 106, 193);
  color: #fff;
}

.mnewsCenter {
  margin: 0 5px;
  height: 484;
  h3 {
    margin: 5px 0 0 0;
    font-weight: bold;
  }
  p {
    color: #a7a7a7;
    margin: 0;
  }
  p:nth-child(4) {
    text-align: right;
    margin: 0 0 5px 0;
  }
  img {
    object-fit: cover;
    border-radius: 5px;
  }
  .bottomItem {
    @include flex("center", "center");
    .item {
      margin: 0 5px;
      width: 49%;
      img {
        height: 120px;
        object-fit: cover;
        border-radius: 5px;
      }
      h3,
      p {
        padding: 0 5px;
      }
    }
  }
}

.servAv {
  img {
    width: 100px;
    height: 100px;
  }
}

.brandImage {
  margin: 15px 15px;
}

#cardView {
  // margin-left: 15px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 160px 0 160px;
  margin-top: 60px;
  h3 {
    width: 85px;
    font-size: 12px;
    padding-top: 6px;
    transform: scale(0.7);
    word-wrap: break-word;
    white-space: normal !important;
    color: #777777;
  }
}
::-webkit-scrollbar {
  display: none;
}

.resent-search-tag3 {
  display: inline-block;

  font-size: 9pt;
  text-align: center;
  border-radius: 3px;
  margin: 10px 3px 25px 3px;
}

.cardActive {
  transform: scale(1.1);
  border: 1px solid rgba($color: red, $alpha: 0.7);
  h3 {
    color: #2a2a2a !important;
    font-weight: bold;
  }
}

.cardsBox {
  z-index: 2;
}
</style>